<template>
  <div>
    <div class="page-header panel">
      <h3 class="title">
        Notification
      </h3>
    </div>
    <el-row :gutter="0">
      <el-col :span="24">
        <div class="panel" style="padding-bottom: 1px">
          <el-form ref="form1" :model="form1" label-width="200px">
            <el-row>
              <el-col :span="22">
                <el-form-item label="Notification Type">
                  <el-select v-model="notification_type" placeholder="Select">
                    <el-option
                      :disabled="notificationType === 'edit' ? true : false"
                      v-for="item in notification_types"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="2">
                <el-button
                  :disabled="
                    notificationType === 'edit' &&
                      this.formFcm.is_notification_sent === true
                  "
                  type="primary"
                  @click="submit"
                  >{{
                    notificationId !== "" && notificationType === "edit"
                      ? "Өөрчлөх"
                      : "Үүсгэх"
                  }}</el-button
                >
              </el-col>
            </el-row>
          </el-form>
        </div>
        <div v-if="notification_type != ''" class="panel">
          <div v-if="notification_type == 'sms'">
            <!-- SMS -->
            <el-form
              class="block"
              ref="formSms"
              :model="form1"
              label-width="200px"
              :rules="smsRules"
            >
              <el-form-item label="Campaign name" prop="campaign_name">
                <el-input
                  placeholder="Enter Campaign name"
                  v-model="form1.campaign_name"
                ></el-input>
              </el-form-item>
              {{ form1.campaign_name }}
              <el-form-item label="SMS text" prop="notification_body">
                <el-input
                  v-model="form1.notification_body"
                  type="textarea"
                  placeholder="Enter notification Body"
                />
              </el-form-item>
              <el-form-item label="Scheduling Time" prop="notification_time">
                <el-time-picker
                  v-model="form1.notification_time"
                  :picker-options="{
                    selectableRange: getSelectableRange
                  }"
                  placeholder="Select the time"
                  value-format="HH:mm:ss"
                >
                </el-time-picker>
              </el-form-item>
              <el-form-item label="User List Data" prop="payload">
                <el-input
                  v-model="form1.payload"
                  type="text-area"
                  placeholder="User List Data"
                  clearable
                >
                </el-input>
              </el-form-item>
            </el-form>
          </div>
          <div v-else-if="notification_type == 'notification'">
            <!-- FCM -->
            <el-form
              ref="formFcm"
              :model="formFcm"
              :rules="fcmRules"
              label-width="200px"
              class="demo-ruleForm"
            >
              <el-row :gutter="20">
                <el-col :span="12">
                  <el-form-item label="Campaign name" prop="campaign_name">
                    <el-input
                      placeholder="Enter Campaign name"
                      v-model="formFcm.campaign_name"
                    ></el-input>
                  </el-form-item>
                  <el-form-item
                    v-if="notificationType === 'edit'"
                    label="Үүссэн хугацаа"
                  >
                    <div>{{ this.formFcm.created_at }}</div>
                  </el-form-item>
                  <el-form-item
                    v-if="notificationType === 'edit'"
                    label="Илгээгдсэн эсэх"
                    prop="is_notification_sent"
                  >
                    <el-tag
                      size="mini"
                      effect="dark"
                      :type="
                        this.formFcm.is_notification_sent == false
                          ? 'danger'
                          : 'success'
                      "
                    >
                      {{
                        this.formFcm.is_notification_sent == false
                          ? "Not Worked"
                          : "Worked"
                      }}
                    </el-tag>
                  </el-form-item>
                  <el-form-item
                    v-if="
                      notificationType === 'edit' &&
                        this.formFcm.is_notification_sent === true
                    "
                    label="Ажилласан хугацаа"
                  >
                    <div>{{ this.formFcm.work_at }}</div>
                  </el-form-item>
                  <el-form-item
                    label="Notification title"
                    prop="notification_title"
                  >
                    <el-input
                      placeholder="Enter optional title"
                      v-model="formFcm.notification_title"
                    ></el-input>
                  </el-form-item>
                  <el-form-item
                    label="Notification Body"
                    prop="notification_body"
                  >
                    <el-input
                      v-model="formFcm.notification_body"
                      type="textarea"
                      placeholder="Enter notification Body"
                    />
                  </el-form-item>
                  <el-form-item
                    label="Notification image (optional)"
                    prop="notification_image_url"
                  >
                    <el-input
                      placeholder="Example: https://yourapp.com/image.png"
                      v-model="formFcm.notification_image_url"
                    ></el-input>
                  </el-form-item>
                  <el-form-item
                    label="Илгээх платформ"
                    prop="notification_type1"
                  >
                    <el-select
                      v-model="formFcm.notification_type1"
                      placeholder="Select"
                    >
                      <el-option
                        v-for="item in notification_types1"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>

                  <el-form-item
                    v-if="formScheduled.is_scheduled === false"
                    label="Илгээх хугацааны төрөл"
                    prop="notification_main_time"
                  >
                    <el-select
                      v-model="formFcm.notification_main_time"
                      placeholder="Select"
                    >
                      <el-option
                        v-for="item in notification_times"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value"
                      >
                      </el-option>
                    </el-select>
                  </el-form-item>
                  <el-form-item
                    v-if="
                      formFcm.notification_main_time === 'scheduled' &&
                        formScheduled.is_scheduled === false
                    "
                    label="Илгээх хугацаа"
                    prop="notification_schedule_time"
                  >
                    <!-- <el-time-picker
                      v-model="formFcm.notification_schedule_time"
                      :picker-options="{
                        selectableRange: getSelectableRange
                      }"
                      placeholder="Select the time"
                      value-format="HH:mm:ss"
                    >
                    </el-time-picker> -->
                    <el-date-picker
                      v-model="formFcm.notification_schedule_time"
                      type="datetime"
                      placeholder="Select date and time"
                      :picker-options="pickerOptions"
                    >
                    </el-date-picker>
                  </el-form-item>
                  <el-form-item label="User List Data" prop="send_list">
                    <el-input
                      v-model="formFcm.send_list"
                      type="text-area"
                      placeholder="User List Data"
                      clearable
                    >
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="12">
                  <el-form
                    label-width="200px"
                    class="block"
                    ref="formDynamic"
                    :model="formDynamic"
                    :rules="dynamicNotificationRules"
                  >
                    <el-form-item label="Дотоод холбоотой эсэх">
                      <el-checkbox v-model="formDynamic.is_dynamic" />
                    </el-form-item>
                    <div v-if="formDynamic.is_dynamic === true">
                      <el-form-item
                        prop="type"
                        label="Dynamic notification Type"
                      >
                        <el-select
                          v-model="formDynamic.type"
                          placeholder="Select"
                        >
                          <el-option
                            v-for="item in dynamic_notification_types"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                    <div
                      v-if="
                        formDynamic.type === 'open_page' &&
                          formDynamic.is_dynamic === true
                      "
                    >
                      <el-form-item prop="page_name" label="open page type">
                        <el-select
                          v-model="formDynamic.page_name"
                          placeholder="Select"
                        >
                          <el-option
                            v-for="item in dn_page_types"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                      <div v-if="formDynamic.page_name === 'orders'">
                        <el-form-item label="Order refer" prop="order_refer">
                          <el-input
                            style="width: 50%;"
                            placeholder="Enter order refer"
                            v-model="formDynamic.order_refer"
                          ></el-input>
                        </el-form-item>
                      </div>
                    </div>
                    <div
                      v-else-if="
                        formDynamic.type === 'open_service' &&
                          formDynamic.is_dynamic === true
                      "
                    >
                      <el-form-item label="service name" prop="service_name">
                        <el-select
                          v-model="formDynamic.service_name"
                          placeholder="Select"
                        >
                          <el-option
                            v-for="item in servicesList"
                            :key="item.id"
                            :label="item.name_mon"
                            :value="item.value"
                          >
                          </el-option>
                        </el-select>
                      </el-form-item>
                    </div>
                    <div
                      v-else-if="
                        formDynamic.type === 'open_outlet' &&
                          formDynamic.is_dynamic === true
                      "
                    >
                      <el-form-item label="outlet name" prop="outlet_name">
                        <el-select
                          filterable
                          placeholder="Select"
                          @change="onChangeOutlet"
                          v-model="formDynamic.outlet_name"
                          value-key="_id"
                        >
                          <el-option
                            v-for="item in allOutletList"
                            :key="item._id"
                            :label="item.outlet_name"
                            :value="item.outlet_name"
                          />
                        </el-select>
                      </el-form-item>
                    </div>
                    <div
                      v-else-if="
                        formDynamic.type === 'open_outlet_item' &&
                          formDynamic.is_dynamic === true
                      "
                    >
                      <el-form-item label="outlet name" prop="outlet_name">
                        <el-select
                          filterable
                          placeholder="Select"
                          @change="onChangeOutlet"
                          v-model="formDynamic.outlet_name"
                          value-key="_id"
                        >
                          <el-option
                            v-for="item in allOutletList"
                            :key="item._id"
                            :label="item.outlet_name"
                            :value="item.outlet_name"
                          />
                        </el-select>
                      </el-form-item>
                      <el-form-item label="item pkey" prop="pkey">
                        <el-select
                          placeholder="Select"
                          @change="onChangeOutletItemPkey"
                          v-model="formDynamic.open_outlet_item_pkey"
                        >
                          <el-option
                            v-for="item in itemPKeys"
                            :key="item._id"
                            :label="item.name_mon"
                            :value="item.pkey"
                          />
                        </el-select>
                      </el-form-item>
                    </div>
                  </el-form>
                  <el-form
                    class="block"
                    ref="formScheduled"
                    :model="formScheduled"
                    :rules="scheduledNotificationRules"
                    label-width="200px"
                  >
                    <el-form-item label="is Scheduled notification">
                      <el-checkbox v-model="formScheduled.is_scheduled" />
                    </el-form-item>
                    <div v-if="formScheduled.is_scheduled === true">
                      <el-form-item
                        label="Scheduling Date range"
                        prop="scheduled_date_range"
                      >
                        <el-date-picker
                          v-model="formScheduled.scheduled_date_range"
                          type="daterange"
                          start-placeholder="Start Date"
                          end-placeholder="End Date"
                          :picker-options="datePickerOptions"
                          value-format="yyyy-MM-dd"
                        >
                        </el-date-picker>
                      </el-form-item>
                      <el-form-item
                        label="Scheduling Time"
                        prop="scheduled_work_time"
                      >
                        <el-time-picker
                          v-model="formScheduled.scheduled_work_time"
                          :picker-options="{
                            selectableRange: getSelectableRange
                          }"
                          placeholder="Select the time"
                          value-format="HH:mm:ss"
                        >
                        </el-time-picker>
                      </el-form-item>
                      <div>
                        <el-form-item
                          label="Scheduling Work Period"
                          prop="scheduled_period"
                        >
                          <el-select
                            style="display: flex; flex: 1;"
                            v-model="formScheduled.scheduled_period"
                            multiple
                            placeholder="Select"
                          >
                            <el-option
                              v-for="item in scheduled_periods"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value"
                            >
                            </el-option>
                          </el-select>
                        </el-form-item>
                      </div>
                    </div>
                  </el-form>
                </el-col>
              </el-row>
            </el-form>
          </div>
        </div>
      </el-col>
    </el-row>
    <el-row
      v-if="notificationType === 'edit' && notificationId !== ''"
      :gutter="0"
    >
      <div class="panel">
        <el-table :data="notificationLogData.logs">
          <el-table-column prop="created_at" label="Created date" />
          <el-table-column prop="work_at" label="Worked at">
            <template slot-scope="scope">
              {{
                scope.row.work_at === "Invalid Date"
                  ? "Null"
                  : scope.row.work_at
              }}
            </template>
          </el-table-column>
          <el-table-column prop="is_worked" label="Is worked" width="120px">
            <template slot-scope="scope">
              <el-tag
                size="mini"
                effect="dark"
                :type="scope.row.is_worked == false ? 'danger' : 'success'"
              >
                {{ scope.row.is_worked == false ? "Pending" : "Sent" }}
              </el-tag>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </el-row>
  </div>
</template>

<script>
import service from "@/helpers/services.js";
import { getUserName } from "../../utils/auth";
export default {
  name: "CreateNotification",
  mounted() {
    if (
      this.$route.params.hasOwnProperty("id")
      // this.$route.params.hasOwnProperty("type") &&
      // this.$route.params.type === "edit"
    ) {
      this.notificationId = this.$route.params.id;
      this.notificationType = "edit";
      this.getNotificationDetails(this.notificationId);
    }
  },
  computed: {
    getSelectableRange() {
      return "00:00:00 - 23:59:59";
    },
    // getSelectableRange() {
    //   let date = new Date();
    //   let time =
    //     date.getHours() +
    //     ":" +
    //     date.getMinutes() +
    //     ":" +
    //     date.getSeconds() +
    //     " - 23:59:59";
    //   return time;
    // }
    dynamicNotificationRules: {
      cache: false,
      get() {
        let rules = {
          type: {
            required: true,
            message: "Төрөл сонгоно уу",
            trigger: "blur"
          },
          page_name: {
            required: false,
            message: "Нээх хуудсын нэрийг оруулна уу",
            trigger: "blur"
          },
          service_name: {
            required: false,
            message: "Нээх service-ийн нэрийг сонгоно уу",
            trigger: "blur"
          },
          outlet_name: {
            required: false,
            message: "Нээх outlet-ийн нэрийг оруулна уу",
            trigger: "blur"
          },
          open_outlet_item_name: {
            required: false,
            message: "Нээх outlet-ийн нэрийг оруулна уу",
            trigger: "blur"
          },
          open_outlet_item_pkey: {
            required: false,
            message: "Нээх outlet_item_pkey-ийн нэрийг оруулна уу",
            trigger: "blur"
          }
        };
        if (this.formDynamic.type === "open_page")
          rules.page_name.required = true;
        else if (this.formDynamic.type === "open_service")
          rules.service_name.required = true;
        else if (this.formDynamic.type === "open_outlet")
          rules.outlet_name.required = true;
        else if (this.formDynamic.type === "open_outlet_item") {
          rules.open_outlet_item_name.required = true;
          rules.open_outlet_item_pkey.required = true;
        }
        return rules;
      }
    },
    scheduledNotificationRules: {
      cache: false,
      get() {
        let rules = {
          scheduled_date_range: {
            required: false,
            message: "Date range сонгоно уу",
            trigger: "blur"
          },
          scheduled_work_time: {
            required: false,
            message: "Work time оруулна уу",
            trigger: "blur"
          },
          scheduled_period: {
            required: false,
            message: "Work period оруулна уу",
            trigger: "blur"
          }
        };
        if (this.formScheduled.is_scheduled === true) {
          rules.scheduled_date_range.required = true;
          rules.scheduled_work_time.required = true;
          rules.scheduled_period.required = true;
        }
        return rules;
      }
    }
  },
  watch: {
    $route(to, from) {
      if (
        to &&
        to.path === "/createNotification" &&
        from.params &&
        from.params.id
      ) {
        this.formFcm.campaign_name = "";
        this.notification_type = "";
        this.notificationType = "create";
        this.formFcm.notification_title = "";
        this.formFcm.notification_body = "";
        this.formFcm.notification_image_url = "";
        this.formFcm.send_list = [];
        this.formScheduled.is_scheduled = false;
        this.formDynamic.is_dynamic = false;
        this.formFcm.notification_type1 = "";
        this.formScheduled.scheduled_work_time = "";
        this.formScheduled.scheduled_period = [];
        this.formScheduled.scheduled_date_range = [];
        this.formDynamic.type = "";
        this.formDynamic.service_name = "";
        this.formDynamic.page_name = "";
        this.formDynamic.order_refer = "";
        this.formDynamic.outlet_name = "";
        this.formDynamic.open_outlet_item_name = "";
        this.formDynamic.open_outlet_item_pkey = "";
      }
    },
    "formDynamic.type"(val) {
      if (val === "open_service" && this.servicesList.length === 0) {
        this.getServices();
      } else if (
        (val === "open_outlet" || val === "open_outlet_item") &&
        this.allOutletList.length === 0
      ) {
        this.getAllOutletName();
      }
    }
    // "formDynamic.open_outlet_item_name"() {
    //   if (this.formDynamic.isSetPkey === true) {
    //     this.getMenusByOutlet();
    //     this.formDynamic.open_outlet_item_pkey = "";
    //   }
    //   this.formDynamic.isSetPkey = true;
    // }
  },
  data() {
    return {
      pickerOptions: {
        disabledDate(time) {
          return time.getTime() < Date.now() - 8.64e7;
          // return time.getTime() < Date.now();
        }
        // selectableRange: '18:30:00 - 20:30:00'
      },
      notificationId: "",
      notificationType: "create",
      notification_type: "",
      isFcmRequired: false,
      user: "",
      targets: [],
      servicesList: [],
      allOutletList: [],
      notificationLogData: {
        logs: []
      },
      itemPKeys: [],
      fcmRules: {
        campaign_name: [
          {
            required: true,
            message: "Campaign нэр оруулна уу",
            trigger: "blur"
          },
          {
            min: 2,
            message: "Хамгийн багадаа 2 үсэг оруулна уу",
            trigger: "blur"
          }
        ],
        notification_title: [
          {
            required: true,
            message: "Та гарчиг оруулна уу",
            trigger: "blur"
          },
          {
            min: 2,
            message: "Хамгийн багадаа 2 үсэг оруулна уу",
            trigger: "blur"
          }
        ],
        notification_body: [
          {
            required: true,
            message: "Текст оруулна уу",
            trigger: "blur"
          },
          {
            min: 2,
            message: "Хамгийн багадаа 2 үсэг оруулна уу",
            trigger: "blur"
          }
        ],
        notification_image_url: [
          {
            required: false,
            // pattern: /(http(s?):)([/|.|\w|\s|-])*\.(?:jpg|gif|png)/g,
            // eslint-disable-next-line
            pattern: /^(http:\/\/www\.|https:\/\/www\.|http:\/\/|https:\/\/)?[a-z0-9]+([\-\.]{1}[a-z0-9]+)*\.[a-z]{2,5}(:[0-9]{1,5})?(\/.*)?$/g,
            message: "Зурагны URL биш байна та зурагны URL-аа шалгана уу",
            trigger: "blur"
          }
        ],
        notification_type1: [
          {
            required: true,
            message: "Type сонгоно уу",
            trigger: "change"
          }
        ],
        notification_date_rage: [
          {
            required: true,
            message: "Ажиллах өдрийн range сонгоно уу",
            trigger: "blur"
          }
        ],
        notification_time: [
          {
            required: true,
            message: "Ажиллах цаг сонгоно уу",
            trigger: "blur"
          }
        ],
        notification_main_time: [
          {
            required: true,
            message: "Ажиллах цагийн төрөл сонгоно уу",
            trigger: "blur"
          }
        ],
        notification_schedule_time: [
          {
            required: true,
            message: "Ажиллах цаг сонгоно уу",
            trigger: "blur"
          }
        ],
        notification_name: [
          {
            min: 2,
            message: "Notification ",
            trigger: "blur"
          }
        ],
        send_list: [
          {
            required: true,
            message: "Хэрэглэгчийн Дата оруулна уу",
            trigger: "blur"
          }
        ]
      },
      smsRules: {
        campaign_name: [
          {
            required: true,
            message: "Campaign нэр оруулна уу",
            trigger: "blur"
          },
          {
            min: 2,
            message: "Хамгийн багадаа 2 үсэг оруулна уу",
            trigger: "blur"
          }
        ],
        notification_title: [
          {
            required: true,
            message: "Та гарчиг оруулна уу",
            trigger: "blur"
          },
          {
            min: 2,
            message: "Хамгийн багадаа 2 үсэг оруулна уу",
            trigger: "blur"
          }
        ],
        notification_body: [
          {
            required: true,
            message: "Текст оруулна уу",
            trigger: "blur"
          },
          {
            min: 2,
            message: "Хамгийн багадаа 2 үсэг оруулна уу",
            trigger: "blur"
          }
        ],
        notification_time: [
          {
            required: true,
            message: "Ажиллах цаг сонгоно уу",
            trigger: "blur"
          }
        ],
        payload: [
          {
            required: true,
            message: "Хэрэглэгчийн Дата оруулна уу",
            trigger: "blur"
          },
          {
            validator: (rule, value, callback) => {
              if (this.isJson(value)) {
                callback();
              } else {
                callback(new Error("Json форматтай биш байна"));
              }
            },
            message: "Json форматтай биш байна",
            trigger: "blur"
          }
        ]
      },
      // campaign_name: "",
      form1: {
        campaign_name: "",
        notification_title: "",
        notification_body: "",
        notification_image_url: "",
        notification_time: "",
        send_type: "single"
      },
      formScheduled: {
        is_scheduled: false,
        scheduled_date_range: "",
        scheduled_work_time: "",
        scheduled_period: []
      },
      formDynamic: {
        is_dynamic: false,
        type: "",
        open_page: "",
        page_name: "",
        service_name: "",
        outlet_name: "",
        order_refer: "",
        open_outlet_item_name: "",
        open_outlet_item_pkey: "",
        isSetPkey: false
      },
      formFcm: {
        notification_schedule_time: "",
        notification_main_time: "",
        notification_type1: "",
        campaign_name: "",
        notification_title: "",
        is_notification_sent: false,
        notification_body: "",
        notification_image_url: "",
        notification_name: "",
        notification_date_rage: "",
        notification_time: "",
        send_list: "",
        created_at: "",
        work_at: ""
      },
      start_at: new Date(),
      value1: "",
      datePickerOptions: {
        disabledDate: time => {
          let beginDateVal = new Date();
          beginDateVal.setDate(beginDateVal.getDate() - 1);
          if (beginDateVal) {
            return time.getTime() <= beginDateVal;
          }
        },
        firstDayOfWeek: 1,
        format: "yyyy-MM-dd"
      },
      notification_types: [
        {
          value: "notification",
          label: "Notification"
        },
        {
          value: "sms",
          label: "SMS"
        }
      ],
      notification_times: [
        {
          value: "now",
          label: "Now"
        },
        {
          value: "scheduled",
          label: "Scheduled"
        }
      ],
      dn_page_types: [
        {
          value: "orders",
          label: "orders"
        },
        {
          value: "coupons",
          label: "coupons"
        }
      ],
      dynamic_notification_types: [
        {
          value: "open_page",
          label: "Хуудас нээх"
        },
        {
          value: "open_service",
          label: "Service нээх"
        },
        {
          value: "open_outlet",
          label: "Outlet нээх"
        },
        {
          value: "open_outlet_item",
          label: "Outlet item нээх"
        }
      ],
      notification_types1: [
        {
          value: "phone",
          label: "Phone"
        },
        {
          value: "topic",
          label: "Topic"
        }
      ],
      scheduled_periods: [
        {
          value: "Mon",
          label: "Monday"
        },
        {
          value: "Tue",
          label: "Tuesday"
        },
        {
          value: "Wed",
          label: "Wednesday"
        },
        {
          value: "Thu",
          label: "Thursday"
        },
        {
          value: "Fri",
          label: "Friday"
        },
        {
          value: "Sat",
          label: "Saturday"
        },
        {
          value: "Sun",
          label: "Sunday"
        }
      ],
      payloadJSON: "",
      sendTypeList: [
        {
          value: "all",
          label: "Бүх хэрэглэгч"
        },
        {
          value: "single",
          label: "Жагсаалт"
        }
      ]
    };
  },
  created() {
    getUserName().then(name => {
      this.user = name.attributes.email.split("@")[0];
    });
  },
  methods: {
    getNotificationDetails(id) {
      service
        .getNotificationDetails(id)
        .then(response => {
          if (response.status == "success") {
            this.notificationLogData = response.data;
            this.formFcm.campaign_name = response.data.campaign_name;
            this.notification_type = response.data.campaign_type;
            this.formFcm.notification_title = response.data.title;
            this.formFcm.notification_body = response.data.body;
            this.formFcm.notification_image_url = response.data.img_url;
            this.formFcm.send_list = response.data.send_list.toString();
            this.formScheduled.is_scheduled = response.data.is_scheduled;
            this.formDynamic.is_dynamic = response.data.is_dynamic_notification;
            this.formFcm.notification_type1 = response.data.notification_type;
            this.formFcm.created_at = response.data.created_at;
            this.formFcm.work_at = response.data.work_at;
            this.formFcm.is_notification_sent = response.data.is_worked;
            if ([true, 1].includes(response.data.is_scheduled)) {
              this.formScheduled.scheduled_work_time = response.data.work_time;
              this.formScheduled.scheduled_period = response.data.work_period;
              this.formScheduled.scheduled_date_range = [
                response.data.work_start_date,
                response.data.work_end_date
              ];
            } else if (
              !["Invalid Date", ""].includes(response.data.notification_time)
            ) {
              this.formFcm.notification_main_time = "scheduled";
              this.formFcm.notification_schedule_time =
                response.data.notification_time;
            }
            if ([true, 1].includes(response.data.is_dynamic_notification)) {
              this.formDynamic.type = response.data.dynamic_notification_type;
              const dnData =
                response.data.dynamic_notification_payload.data
                  .dynamicNotification.body;
              if (response.data.dynamic_notification_type === "open_service") {
                this.formDynamic.service_name = dnData.service.service_name;
              } else if (
                response.data.dynamic_notification_type === "open_page"
              ) {
                this.formDynamic.page_name = dnData.page.page_name;
                if (
                  dnData.page.page_name === "orders" &&
                  dnData.page.order_refer
                ) {
                  this.formDynamic.order_refer = dnData.page.order_refer;
                }
              } else if (
                response.data.dynamic_notification_type === "open_outlet"
              ) {
                this.formDynamic.outlet_name = dnData.outlet.outlet_name;
              } else if (
                response.data.dynamic_notification_type === "open_outlet_item"
              ) {
                this.formDynamic.outlet_name = dnData.outlet.outlet_name;
                // this.formDynamic.open_outlet_item_pkey =
                //   dnData.outlet.item_pkey;
                // this.formDynamic.open_outlet_item_name =
                //   dnData.outlet.item_name;
                this.itemPKeys = [];
                service
                  .getMenusByOutlet({
                    outlet_name: this.formDynamic.outlet_name
                  })
                  .then(menuResponse => {
                    menuResponse.forEach(el => {
                      if (el.pkey === dnData.outlet.item_pkey) {
                        this.formDynamic.open_outlet_item_pkey = el.pkey;
                        this.formDynamic.open_outlet_item_name = el.name_mon;
                      }
                      this.itemPKeys.push({
                        id: el._id,
                        pkey: el.pkey,
                        name_mon: el.name_mon
                      });
                    });
                  });
              }
            }
          } else {
            this.$notify({
              title: "Анхааруулга",
              message: "Алдаа: " + response.error.message,
              type: "error"
            });
          }
        })
        .catch(error => {
          this.$notify({
            title: "Анхааруулга",
            message: "Алдаа: " + error.message,
            type: "error"
          });
        });
    },
    getServices() {
      service.getServices().then(servicesResponse => {
        let tempData = [];
        servicesResponse.forEach(el => {
          let tempEl = {};
          if (
            el.status === "active" &&
            el.is_show === true &&
            el.deleted === false
          ) {
            tempEl.name_eng = el.name_eng;
            tempEl.name_mon = el.name_mon;
            tempEl.value = el.value;
            tempEl.id = el.id;
            tempData.push(tempEl);
          }
        });
        this.servicesList = tempData;
      });
    },
    getAllOutletName() {
      const payload = {
        includeFields: ["full_name_eng"],
        is_verified: true
      };
      service.getOutlets(payload).then(res => {
        if (res.data.status === "success") {
          this.allOutletList = res.data.data;
        }
      });
    },
    getMenusByOutlet() {
      this.itemPKeys = [];
      service
        .getMenusByOutlet({ outlet_name: this.formDynamic.outlet_name })
        .then(menuResponse => {
          menuResponse.forEach(el => {
            this.itemPKeys.push({
              id: el._id,
              pkey: el.pkey,
              name_mon: el.name_mon
            });
          });
        });
    },
    disabledDueDate() {
      return new Date().getTime() < Date.now() - 8.64e7;
    },
    submit() {
      if (this.notification_type == "sms") {
        this.$refs["formSms"].validate(valid => {
          if (valid) {
            let payload = {
              campaign_name: this.form.campaign_name,
              campaign_type: "sms",
              title: this.form.notification_title,
              body: this.form.notification_body,
              send_type: "single",
              work_at:
                this.form.notification_date + " " + this.form.notification_time
            };
            if (!Array.isArray(JSON.parse(this.form.payload))) {
              this.$notify({
                title: "Анхааруулга",
                message:
                  "Хэрэглэгчийн жагсаалтын бүтэц зөрсөн. (Array) байх ёстой",
                type: "error"
              });
            } else {
              if (JSON.parse(this.form.payload).every(this.checkPhone)) {
                payload["payload"] = JSON.parse(this.form.payload);
                // const loading = this.$loading({
                //   lock: true,
                //   text: "Loading",
                //   spinner: "el-icon-loading",
                //   background: "rgba(0, 0, 0, 0.7)"
                // });
                // service
                //   .createNotification(payload)
                //   .then(response => {
                //     if (response.status == "success") {
                //       this.$notify({
                //         title: "Амжилттай",
                //         message: "Амжилттай үүслээ",
                //         type: "success"
                //       });
                //     } else {
                //       this.$notify({
                //         title: "Амжилтгүй",
                //         message:
                //           response.error.message +
                //           " code: " +
                //           response.error.code,
                //         type: "warning"
                //       });
                //     }
                //     loading.close();
                //   })
                //   .catch(error => {
                //     loading.close();
                //     this.$notify({
                //       title: "Анхааруулга",
                //       message: "Алдаа: " + error.message,
                //       type: "error"
                //     });
                //   });
              } else {
                this.$notify({
                  title: "Анхааруулга",
                  message:
                    "Хэрэглэгчийн утасны дугааргүй бичлэг байна. Жагсаалтаа шалгана уу",
                  type: "error"
                });
              }
            }
          } else {
            return false;
          }
        });
      } else if (this.notification_type == "notification") {
        this.$refs.formFcm.clearValidate();
        this.$refs["formFcm"].validate(mainFormValid => {
          if (mainFormValid) {
            this.$refs.formDynamic.clearValidate();
            this.$refs["formDynamic"].validate(dynamicFormValid => {
              if (dynamicFormValid) {
                this.$refs.formScheduled.clearValidate();
                this.$refs["formScheduled"].validate(scheduledFormValid => {
                  if (scheduledFormValid) {
                    let payload = {
                      campaign_name: this.formFcm.campaign_name,
                      campaign_type: "notification",
                      user_name: this.user,
                      save_type: this.notificationType,
                      notification_type: this.formFcm.notification_type1,
                      send_list: this.formFcm.send_list.split(/[\s,]+/),
                      notification_time: ["now", ""].includes(
                        this.formFcm.notification_main_time
                      )
                        ? "now"
                        : this.formFcm.notification_schedule_time,
                      notification_id: this.notificationId,
                      notification: {
                        title: this.formFcm.notification_title,
                        body: this.formFcm.notification_body,
                        image: this.formFcm.notification_image_url
                      }
                    };
                    payload.is_scheduled_notification = this.formScheduled.is_scheduled;
                    payload.is_dynamic_notification = this.formDynamic.is_dynamic;
                    if (this.formScheduled.is_scheduled === true) {
                      const scheduled_notification = {
                        work_time: this.formScheduled.scheduled_work_time,
                        work_type: "loop",
                        work_period: this.formScheduled.scheduled_period,
                        work_start_date: this.formScheduled
                          .scheduled_date_range[0],
                        work_end_date: this.formScheduled
                          .scheduled_date_range[1]
                      };
                      payload.scheduled_notification = scheduled_notification;
                    }
                    if (this.formDynamic.is_dynamic === true) {
                      let dynamic_notification = {
                        type: this.formDynamic.type
                      };
                      if (this.formDynamic.type === "open_page") {
                        if (
                          ["", null].includes(this.formDynamic.order_refer) ===
                          true
                        ) {
                          dynamic_notification.order_refer = this.formDynamic.order_refer;
                        }
                        dynamic_notification.page_name = this.formDynamic.page_name;
                      } else if (this.formDynamic.type === "open_service") {
                        dynamic_notification.service_name = this.formDynamic.service_name;
                      } else if (this.formDynamic.type === "open_outlet") {
                        dynamic_notification.outlet_name = this.formDynamic.outlet_name;
                        dynamic_notification.outlet_id = this.formDynamic.outlet_id;
                        dynamic_notification.service_name = this.formDynamic.service_name;
                      } else if (this.formDynamic.type === "open_outlet_item") {
                        dynamic_notification.item_name = this.formDynamic.open_outlet_item_name;
                        dynamic_notification.item_pkey = this.formDynamic.open_outlet_item_pkey;
                        dynamic_notification.outlet_name = this.formDynamic.outlet_name;
                        dynamic_notification.outlet_id = this.formDynamic.outlet_id;
                        dynamic_notification.service_name = this.formDynamic.service_name;
                      }
                      payload.dynamic_notification = dynamic_notification;
                    }
                    const loading = this.$loading({
                      lock: true,
                      text: "Loading",
                      spinner: "el-icon-loading",
                      background: "rgba(0, 0, 0, 0.7)"
                    });
                    service
                      .createNotification(payload)
                      .then(response => {
                        if (response.status == "success") {
                          this.$notify({
                            title: "Амжилттай",
                            message: "Амжилттай илгээгдлээ",
                            type: "success"
                          });
                          this.$router.push({ name: "notificationList" });
                        } else {
                          this.$notify({
                            title: "Амжилтгүй",
                            message:
                              response.error.message +
                              " code: " +
                              response.error.code,
                            type: "warning"
                          });
                        }
                        loading.close();
                      })
                      .catch(error => {
                        loading.close();
                        this.$notify({
                          title: "Анхааруулга",
                          message: "Алдаа: " + error.message,
                          type: "error"
                        });
                      });
                  } else {
                    return false;
                  }
                });
              } else {
                return false;
              }
            });
          } else {
            return false;
          }
        });
      }
    },
    isJson(str) {
      try {
        JSON.parse(str);
      } catch (e) {
        return false;
      }
      return true;
    },
    checkPhone(element) {
      return element.hasOwnProperty("phone");
    },
    checkFcm(element) {
      return element.hasOwnProperty("fcm_token");
    },
    onChangeOutlet(outletName) {
      this.formDynamic.open_outlet_item_pkey = null;
      this.formDynamic.open_outlet_item_name = null;
      const findOutlet = this.allOutletList.find(
        el => el.outlet_name === outletName
      );
      if (findOutlet) {
        this.formDynamic.outlet_id = findOutlet._id;
        this.formDynamic.service_name = findOutlet.service;
      }
      if (this.formDynamic.type === "open_outlet_item") {
        this.getMenusByOutlet();
      }
    },
    onChangeOutletItemPkey(itemPkey) {
      const findItem = this.itemPKeys.find(el => el.pkey === itemPkey);
      if (findItem) {
        this.formDynamic.open_outlet_item_name = findItem.name_mon;
      }
    }
  }
};
</script>
<style>
.el-form-item__label {
  color: #000;
}
</style>
